import React, { useState } from "react"
import { navigate } from "gatsby"
import Input from "@material-ui/core/Input"

import Layout from "../components/layout/layout"
import Container from "@material-ui/core/Container"

import FormControl from "@material-ui/core/FormControl"
import styled from "styled-components"
import PrimaryButton from "../components/buttons/primaryButton"
import CircularProgress from "@material-ui/core/CircularProgress"

const StyledFormControl = styled(FormControl)`
  display: block;
  width: 100%;
  margin-top: 50px;

  button {
    margin-top: 5px;
  }

  input {
    border: 1px solid #ced4da;
    display: block;
    width: 100%;
    color: #6c757d;
    opacity: 1;
    font-size: 1.2rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    text-align: center;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;

    &:hover {
      border: 1px solid #ced4da;
      outline: none;
    }

    &:focus {
      border-color: #80bdff;
    }
  }
`

function encode(key, cycles) {
  for (let i = 0; i < cycles; i++) {
    key = btoa(key)
  }
  return key
}

function decode(key, cycles) {
  for (let i = 0; i < cycles; i++) {
    key = atob(key)
  }
  return key
}

const Admin = () => {
  const [input, setInput] = useState("")
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    if (input === "" || input === " ") {
      return
    } else {
      setLoading(true)
      setTimeout(() => {
        if (decode(input, 10) === "7QX<'yz%;y5{@M<g") {
          navigate("/admindeverdad")
          setLoading(false)
        } else {
          setLoading(false)
          return
        }
      }, 3000)
    }
  }

  return (
    <Layout>
      <Container>
        <StyledFormControl>
          <Input
            type="text"
            disableUnderline={true}
            placeholder="Introduce la contraseña..."
            onChange={event => setInput(encode(event.target.value, 10))}
          />
          <PrimaryButton onClick={handleClick}>Entrar</PrimaryButton>
          {loading === true ? <CircularProgress /> : ""}
        </StyledFormControl>
      </Container>
    </Layout>
  )
}

export default Admin
